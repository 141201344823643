import React, { useState, useEffect } from 'react';
import {
  ImageHeaderWithTextblock,
  TextWithDoubleImages,
  PageHelmet,
} from 'components';
import client from 'cms/client';
import ReactGA from 'react-ga';
import imageUrlBuilder from '@sanity/image-url';
import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);
const urlFor = source => imgBuilder.image(source);

const HeaderInfo = {
  title: 'På lag med fremtiden',
  description: 'Vi ønsker alltid å utgjøre en forskjell ved å støtte og vise omsorg for de som vil opp og frem.',
};

const CommunityInvolvementPage = () => {
  // state to store all community involvements
  const [communityInvolvements, setCommunityInvolvements] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/paa-lag-med-fremtiden');
    client.fetch(
      `*[_type=="communityInvolvement"]{...,imagesWithMetadata[]-> } |
      order(sortId asc)`,
    )
      .then(data => setCommunityInvolvements(data));
  }, []);

  return (
    <>
      <PageHelmet pageTitle={HeaderInfo.title} />

      <ImageHeaderWithTextblock
        title={HeaderInfo.title}
        description={HeaderInfo.description}
        background='tree-by-water'
      />
      <section className={styles.pageContainer}>

        {communityInvolvements.length > 0 && (communityInvolvements.map((involvement, i) => (
          <section className={styles.sectionContainer} key={involvement.title}>
            <Fade>
              <TextWithDoubleImages
                key={involvement.title}
                title={involvement.title && involvement.title}
                text={involvement.text && involvement.text}
                firstImg={involvement.imagesWithMetadata.length && (
                  urlFor(involvement.imagesWithMetadata[0].image)
                    .auto('format')
                    .url()
                )}
                firstImgCaption={involvement.imagesWithMetadata[0].text || null}
                secondImg={involvement.imagesWithMetadata.length > 1 && (
                  urlFor(involvement.imagesWithMetadata[1].image)
                    .auto('format')
                    .url()
                )}
                secondImgCaption={involvement.imagesWithMetadata[1].text || null}
                reversed={!!(i % 2)}
                overlap={false}
                centeredText={involvement.centeredText}
              />
            </Fade>
          </section>
        )))}

      </section>
    </>
  );
};

export default CommunityInvolvementPage;
