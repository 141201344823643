// @flow
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Menu,
  Footer,
} from 'components';
import ReactGA from 'react-ga';
import Routes from 'router/Routes';
import client from 'cms/client';
import styles from './App.module.scss';

const App = () => {
  const [footerAdresses, setFooterAdresses] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuIsOpenAnime = {
    open: {
      opacity: 0.4,
    },
    closed: {
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    },
  };

  const initializeGA = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'production':
        ReactGA.initialize(process.env.REACT_APP_GA_PRODUCTION);
        break;
      case 'staging':
        ReactGA.initialize(process.env.REACT_APP_GA_STAGING, {
          debug: true,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initializeGA();
    client.fetch("*[_type == 'footer'] | order(sortId) {..., 'imageUrl': image.asset->url, 'title': title->title}")
      .then(documents => setFooterAdresses(documents));
  }, []);

  return (
    <div className={styles.app}>
      <Menu setIsOpen={setIsMenuOpen} />
      <motion.div
        initial={isMenuOpen}
        animate={isMenuOpen ? 'open' : 'closed'}
        variants={menuIsOpenAnime}
      >
        <Routes />
        <div className={styles.footerContainer}>
          <Footer footerAdress={footerAdresses} />
        </div>
      </motion.div>
    </div>
  );
};

export default App;
