import React, { useState, useEffect } from 'react';
import {
  Banner,
  TextWithLink,
  ImageHeaderWithTextblock,
  TextWithDoubleImages,
  PageHelmet,
  PartnerLogo,
} from 'components';
import client from 'cms/client';
import ReactGA from 'react-ga';
import imageUrlBuilder from '@sanity/image-url';
import Fade from 'react-reveal/Fade';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);
const urlFor = source => imgBuilder.image(source);

const AboutPage = () => {
  const [header, setHeader] = useState('');
  const [topText, setTopText] = useState(null);
  const [overlapComponents, setOverlapComponents] = useState(null);
  const [textImage, setTextImage] = useState(null);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/om');
    client.fetch('*[_type=="aboutHeader"] ')
      .then(cmsHeader => setHeader(cmsHeader[0]));
  }, []);

  useEffect(() => {
    client.fetch('*[_type=="aboutTopText"] ')
      .then(cmsTopText => setTopText(cmsTopText[0]));
  }, []);

  useEffect(() => {
    client.fetch('*[_type=="aboutOverlap"]{...,firstImagesMeta[]->,secondImagesMeta[]-> }')
      .then(cmsOverlap => {
        setOverlapComponents(cmsOverlap[0]);
      });
  }, []);

  useEffect(() => {
    client.fetch('*[_type=="aboutTextImage"]{...,imagesWithMetadata[]-> }')
      .then(cmsTextImage => {
        setTextImage(cmsTextImage[0]);
      });
  }, []);

  useEffect(() => {
    client.fetch('*[_type=="aboutCollaborators"] ')
      .then(cmsPartners => setPartners(cmsPartners));
  }, []);

  return (
    <>
      <PageHelmet pageTitle='Om Selskapet' />
      <ImageHeaderWithTextblock
        title={(typeof header.title !== 'undefined') ? header.title : ''}
        description={(typeof header.text !== 'undefined') ? header.text : ''}
        background={(typeof header.background !== 'undefined') ? header.background : 'rusty-building'}
        arrow={header.arrowShow && { brand: header.arrowColor }}
      />
      <section className={styles.pageContainer}>

        { topText !== null && (
          <Fade>
            <section className={styles.textContainer}>
              <TextWithLink title={topText.title} text={topText.text} />
            </section>
          </Fade>
        )}

        <Fade>
          <section className={styles.bannerContainer}>
            <Banner
              image='gaselle'
              text={['Tydelig.', 'Handlekraftig.', 'Lyttende.', 'I forkant.']}
              relativePositioning
            />
          </section>
        </Fade>

        { overlapComponents !== null && (
          <Fade>
            <section className={styles.doubleImageContainer}>
              <TextWithDoubleImages
                title={(typeof overlapComponents.firstTitle !== 'undefined') ? overlapComponents.firstTitle : ''}
                text={overlapComponents.firstText}
                firstImg={urlFor(overlapComponents.firstImagesMeta[0].image)
                  .auto('format')
                  .url()}
                firstImgCaption={overlapComponents.firstImagesMeta[0].text || null}
                secondImg={urlFor(overlapComponents.firstImagesMeta[1].image)
                  .auto('format')
                  .url()}
                secondImgCaption={overlapComponents.firstImagesMeta[1].text || null}
              />
            </section>
          </Fade>
        )}

        { overlapComponents !== null && (
          <Fade>
            <section className={styles.doubleImageContainer}>
              <TextWithDoubleImages
                title={(typeof overlapComponents.secondTitle !== 'undefined') ? overlapComponents.secondTitle : ''}
                text={overlapComponents.secondText}
                firstImg={urlFor(overlapComponents.secondImagesMeta[0].image)
                  .auto('format')
                  .url()}
                firstImgCaption={overlapComponents.secondImagesMeta[0].text || null}
                secondImg={urlFor(overlapComponents.secondImagesMeta[1].image)
                  .auto('format')
                  .url()}
                secondImgCaption={overlapComponents.secondImagesMeta[1].text || null}
                reversed
              />
            </section>
          </Fade>
        )}

        { textImage !== null && (
          <Fade>
            <section className={styles.doubleImageContainer}>
              <TextWithDoubleImages
                title={(typeof textImage.title !== 'undefined') ? textImage.title : ''}
                text={textImage.text}
                firstImg={urlFor(textImage.imagesWithMetadata[0].image)
                  .auto('format')
                  .url()}
                firstImgCaption={textImage.imagesWithMetadata[0].text || null}
                secondImg={urlFor(textImage.imagesWithMetadata[1].image)
                  .auto('format')
                  .url()}
                secondImgCaption={textImage.imagesWithMetadata[1].text || null}
                centeredText
              />
            </section>
          </Fade>
        )}

        <Fade>
          <section className={styles.partnersContainer}>
            {partners.map(partner => (
              <PartnerLogo
                // eslint-disable-next-line no-underscore-dangle
                key={partner._id || partner.name}
                name={partner.name}
                logo={urlFor(partner.image)
                  .auto('format')
                  .url()}
              />
            ))}
          </section>
        </Fade>

      </section>
    </>
  );
};

export default AboutPage;
