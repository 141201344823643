import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';
import {
  AboutPage,
  HomePage,
  Page404,
  PeoplePage,
  ProjectsPage,
  ProjectPage,
  WhatWeDoPage,
  CommunityInvolvementPage,
  PrivacyStatement,
  QmthPage,
  SalesTermsPage,
  BackePage,
} from 'pages';

const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route path='/menneskene' component={PeoplePage} />
      <Route exact path='/prosjekter' component={ProjectsPage} />
      <Route exact path='/prosjekter/:name' component={ProjectPage} />
      <Route path='/hva-vi-gjor' component={WhatWeDoPage} />
      <Route exact path='/om' component={AboutPage} />
      <Route exact path='/paa-lag-med-fremtiden' component={CommunityInvolvementPage} />
      <Route exact path='/personvern' component={PrivacyStatement} />
      <Route exact path='/qualified-multitenant-hoster-qmth-program' component={QmthPage} />
      <Route exact path='/informasjon-til-backes-brukere' component={BackePage} />
      <Route exact path='/salgsbetingelser' component={SalesTermsPage} />
      <Route path='*' component={Page404} />
    </Switch>
  );
};

export default withRouter(Routes);
