import React from 'react';
import { Cycler } from 'react-text-scrambler';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const Header = () => {
  const HeaderInfo = {
    description: 'Vi skaper og tar vare på',
    words: ['digitale løsninger', 'forretningsmuligheter', 'gode brukeropplevelser'],
  };
  const csText = {
    des: 'Trenger du hjelp?',
    des1: 'Gå til kundeservice',
  };

  return (
    <>
      <header className={styles.header}>
        <section className={styles.headerContainer}>
          <div className={styles.textContainer}>
            <h1 className={styles.description}>{HeaderInfo.description}</h1>
            <div className={styles.description}>
              <Cycler
                duration={5000}
                strings={HeaderInfo.words}
              />
            </div>
          </div>
          <div className={styles.csContainer}>
            <InnitArrow className={styles.arrow} />
            <a className={styles.csblock} href='https://kundeservice.innit.no/'>
              <p className={styles.csText}>{csText.des}</p>
              <p className={styles.csText}>{csText.des1}</p>
            </a>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
