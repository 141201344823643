import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import {
  FactsBlock,
  PartnerLogo,
  ProductMobileShowing,
  TextBlock,
  TextWithSingleImage,
  SingleImage,
  PageHelmet,
} from 'components';
import Fade from 'react-reveal/Fade';
import imageUrlBuilder from '@sanity/image-url';
import client from 'cms/client';
import * as BlockContent from '@sanity/block-content-to-react';
import Iframe from 'react-iframe';
import Header from './Header/Header';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);

const urlFor = source => imgBuilder.image(source);

const serializers = {
  types: {
    code: ({ ...props } = {}, { props: { node: { language, code } } } = props) => (
      <pre data-language={language}>
        <code>{code}</code>
      </pre>
    ),
  },
};

const ProjectPage = () => {
  const { name } = useParams();
  const [redirect, setRedirect] = useState(false);
  const [project, setProject] = useState(null);

  useEffect(() => {
    ReactGA.pageview(`/prosjekter/${name}`);
    client.fetch(`*[_type == "project" && titleHeader == "${name}"]{ ..., imageHeaderWithMetadata->, imageCustomerInfoWithMetadata->, imageIssueWithMetadata->, single_ImageWithMetadata->, }`)
      .then(cmsContent => {
        if (cmsContent.length > 0) {
          setProject(cmsContent[0]);
        } else {
          // redirect
          setRedirect(true);
        }
      });
  }, [name]);

  if (redirect) {
    return <Redirect to='/' />;
  }
  return (
    <>
      { project
      && typeof project !== 'function' && (
        <>
          <PageHelmet pageTitle={project.titleHeader} />
          <Header
            title={project.titleHeader}
            customerText={project.textHeader}
            imgSrc={
              urlFor(project.imageHeaderWithMetadata.image)
                .auto('format')
                .url()
            }
            caption={project.imageHeaderWithMetadata.text && (project.imageHeaderWithMetadata.text)}
          />
          <Fade>
            <div className={styles.container}>
              {project.textCustomerInfo
              && (
              <TextWithSingleImage
                title={project.titleCustomerInfo}
                text={project.textCustomerInfo}
                imgSrc={
                      urlFor(project.imageCustomerInfoWithMetadata.image)
                        .auto('format')
                        .url()
                    }
                caption={project.imageCustomerInfoWithMetadata.text
                  && (project.imageCustomerInfoWithMetadata.text)}
                reversed
              />
              )}
              {project.textIssue
              && (
                <TextWithSingleImage
                  title={project.titleIssue}
                  text={project.textIssue}
                  imgSrc={
                    urlFor(project.imageIssueWithMetadata.image)
                      .auto('format')
                      .url()
                  }
                  caption={project.imageIssueWithMetadata.text
                    && (project.imageIssueWithMetadata.text)}
                />

              )}
              {project.textWhatWeMade && (
                <>
                  <TextBlock
                    title={project.titleWhatWeMade}
                  />
                  <BlockContent
                    renderContainerOnSingleChild
                    className={styles.blockContent}
                    blocks={project.textWhatWeMade}
                    serializers={serializers}
                  />
                </>
              )}
              {project.imagesProductMobileShowing
              && project.imagesProductMobileShowing.length === 3 && (
                <ProductMobileShowing
                  title={project.titleProductMobileShowing}
                  imgUrl1={
                    urlFor(project.imagesProductMobileShowing[0])
                      .auto('format')
                      .url()
                  }
                  imgUrl2={
                    urlFor(project.imagesProductMobileShowing[1])
                      .auto('format')
                      .url()
                  }
                  imgUrl3={
                    urlFor(project.imagesProductMobileShowing[2])
                      .auto('format')
                      .url()
                  }
                />
              )}
              {project.single_ImageWithMetadata && (
                <SingleImage
                  imgUrl={
                    urlFor(project.single_ImageWithMetadata.image)
                      .auto('format')
                      .url()
                  }
                  caption={project.single_ImageWithMetadata.text
                    && (project.single_ImageWithMetadata.text)}
                />
              )}
              {project.textResult && (
                <>
                  <TextBlock
                    title={project.titleResult}
                  />
                  <BlockContent
                    renderContainerOnSingleChild
                    className={styles.blockContent}
                    blocks={project.textResult}
                    serializers={serializers}
                  />
                </>
              )}
              {project.projectVideo && (
                <Iframe
                  url={project.projectVideo}
                  className={styles.videoFrame}
                />
              )}
              {project.textFactsBlock && (
                <FactsBlock
                  title={project.titleFactsBlock}
                  text={project.textFactsBlock}
                  imgUrl={
                    urlFor(project.imageFactsBlock)
                      .auto('format')
                      .url()
                  }
                />
              )}
              {project.textStatement && (
                <>
                  <TextBlock
                    title={project.titleStatement}
                  />
                  <BlockContent
                    renderContainerOnSingleChild
                    className={styles.blockContent_statement}
                    blocks={project.textStatement}
                    serializers={serializers}
                  />
                </>
              )}
              {project.imagesPartners && (
              <section className={styles.partnersContainer}>
                {project.imagesPartners.map(partner => (
                  <PartnerLogo
                  // eslint-disable-next-line no-underscore-dangle
                    key={partner._key}
                    logo={urlFor(partner)
                      .auto('format')
                      .url()}
                  />
                ))}
              </section>
              )}
            </div>
          </Fade>
        </>
      )}
    </>
  );
};

export default ProjectPage;
