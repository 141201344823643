import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import client from 'cms/client';
import ReactGA from 'react-ga';
import imageUrlBuilder from '@sanity/image-url';
import {
  EmployeeInfo, Dropdown, TextWithDoubleImages, PageHelmet,
} from 'components';
import Header from './Header/Header';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);

const urlFor = source => imgBuilder.image(source);

const HeaderInfo = {
  title: 'MENNESKENE',
  description: 'Vi spiller på lag og investerer i mennesker som ønsker å drive faget sitt videre.',
};

const PeoplePage = () => {
  // state to store all employees and one to showthem
  // this way we can filter them without making additional requestes
  const [employees, setEmployees] = useState([]);
  const [shownEmployees, setShownEmployees] = useState([]);

  // States to store possible locations and what the user chooses
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // States to store possibale departments and what the user chooses
  const [departments, setdepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  // state to store page content
  const [content, setContent] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/menneskene');

    client.fetch(`*[_type=="locationTags"] | order(location){
        location
      }`)
      // eslint-disable-next-line max-len
      .then(locationsDocument => setLocations(locationsDocument.map(locationsEntry => locationsEntry.location)));

    client.fetch(`*[_type=="departmentTags"] | order(department){
      department
      }`)
      // eslint-disable-next-line max-len
      .then(departmentsDocument => setdepartments(departmentsDocument.map(departmentsEntry => departmentsEntry.department)));


    client.fetch(`*[_type == 'employee']| order(name){
        name, mail, jobTitle, image, "departments": tagsDepartment[]-> department, "locations":tagsLocation[]->location
      }`)
      .then(emlpoyeesDocument => setEmployees(emlpoyeesDocument));

    client.fetch('*[_type == "employeesSections"]{...,imagesWithMetadata[]-> } | order(sortId)')
      .then(emlpoyeesContent => setContent(emlpoyeesContent));
  }, []);

  useEffect(() => {
    if (selectedLocation || selectedDepartment) {
      let temp = employees;
      if (selectedLocation) {
        temp = temp.filter(
          employee => employee.locations.some(
            loc => selectedLocation.includes(loc),
          ),
        );
      }
      if (selectedDepartment) {
        temp = temp.filter(
          employee => employee.departments.some(
            dep => selectedDepartment.includes(dep),
          ),
        );
      }
      setShownEmployees(temp);
    } else {
      setShownEmployees(employees);
    }
  }, [employees, selectedLocation, selectedDepartment]);

  return (
    <>
      <PageHelmet pageTitle='Menneskene' />
      <Header title={HeaderInfo.title} description={HeaderInfo.description} />
      <section className={styles.tagsWrapper}>
        <h1 className={styles.ourPeople}>Menneskene</h1>
        <div className={styles.tags}>
          <div className={styles.tagWrapper}>
            <Dropdown
              title='Alle lokasjoner'
              list={locations}
              activeItem={selectedLocation}
              setActiveItem={setSelectedLocation}
            />
          </div>
          <div className={styles.tagWrapper}>
            <Dropdown
              title='Alle fagområder'
              list={departments}
              activeItem={selectedDepartment}
              setActiveItem={setSelectedDepartment}
            />
          </div>
        </div>
      </section>
      {employees.length > 0 && (
        <div className={styles.employees}>
          {shownEmployees.map(employee => (
            <EmployeeInfo
              key={employee.name}
              name={employee.name}
              mail={employee.mail}
              title={employee.jobTitle}
              imgUrl={
                urlFor(employee.image)
                  .width(180)
                  .height(180)
                  .auto('format')
                  .url()
              }
            />
          ))}

        </div>
      )}
      {content.length > 0 && (
        <div className={styles.contentWrapper}>
          <Fade>
            {content.map((con, i) => (
              <TextWithDoubleImages
                key={con.sortId}
                title={con.textTitle}
                text={con.text}
                linkText={con.linkText}
                to={con.url}
                isExternal={con.isExternal}
                firstImg={
                  urlFor(con.imagesWithMetadata[0].image)
                    .url()
                }
                firstImgCaption={con.imagesWithMetadata[0].text || null}
                secondImg={
                  urlFor(con.imagesWithMetadata[1].image)
                    .auto('format')
                    .url()
                  }
                secondImgCaption={con.imagesWithMetadata[1].text || null}
                reversed={!!(i % 2)}
                overlap={!!(i % 2)}
                centeredText
              />
            ))}
          </Fade>
        </div>
      )}
    </>
  );
};

export default PeoplePage;
