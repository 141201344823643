import React from 'react';
import PropTypes from 'prop-types';
import { InnitArrowNoPad } from 'assets/icons';
import cx from 'classnames';
import styles from './styles.module.scss';

const ImageHeaderWithTextblock = ({
  title, description, background, arrow,
}) => (
  <header className={cx(styles.header, { [styles[background]]: background })}>
    <div className={styles.innerContainer}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.description}>{description}</h2>
      </div>
    </div>
    <div className={styles.arrowContainer}>
      <InnitArrowNoPad className={cx(styles.arrow, { [styles[arrow.brand]]: arrow })} />
    </div>
  </header>
);

ImageHeaderWithTextblock.defaultProps = {
  arrow: {
    brand: null,
  },
};

ImageHeaderWithTextblock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  background: PropTypes.oneOf(['tree-by-water', 'rusty-building']).isRequired,
  arrow: PropTypes.shape({
    brand: PropTypes.oneOf(['innit-blood', 'innit-red']),
  }),
};

export default ImageHeaderWithTextblock;
