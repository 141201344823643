import React, { useEffect, useState } from 'react';
import {
  ContactInfo,
  ImageWithText,
  TextWithLink,
  TextWithDoubleImages,
  NewsTicker,
  PageHelmet,
  ProductMobileShowing,
} from 'components';
import ReactGA from 'react-ga';
import cx from 'classnames';
import client from 'cms/client';
import imageUrlBuilder from '@sanity/image-url';
import Fade from 'react-reveal/Fade';
import { InnitArrowNoPad } from 'assets/icons';

import Header from './Header/Header';
import styles from './styles.module.scss';


const imgBuilder = imageUrlBuilder(client);

const urlFor = source => imgBuilder.image(source);

const HomePage = () => {
  const [attention, setAttention] = useState([]);
  const [news, setNews] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [content, setContent] = useState([]);
  const [imageCollection, setImageCollection] = useState([]);
  const [projects, setProjects] = useState(() => {
    client.fetch(`*[_type=="projectReferance"] | order(titleProductMobileShowing){
      ..., mobileProjectRef -> , projectRef->
    }`)
    // eslint-disable-next-line max-len
      .then(projectsDocument => setProjects(projectsDocument));
  });

  useEffect(() => {
    ReactGA.pageview('/');
    client.fetch(`*[_type=="homeNews"]{
      newsEntry
    }`)
    // eslint-disable-next-line max-len
      .then(newsDocument => setNews(newsDocument.map(newsEntry => newsEntry.newsEntry)));

    client.fetch('*[_type=="attention"]{...,imageWithMetadata-> }')
    // eslint-disable-next-line max-len
      .then(attentionDocument => setAttention(attentionDocument));

    client.fetch('*[_type=="homeContent"]{...,imagesWithMetadata[]-> } | order(sortId)')
    // eslint-disable-next-line max-len
      .then(homeContentDocument => setContent(homeContentDocument));

    client.fetch('*[_type=="contactInfo"]')
    // eslint-disable-next-line max-len
      .then(contactInfoDocument => setContactInfo(contactInfoDocument));

    client.fetch('*[_type=="imageCollection"]{...,imagesWithMetadata[]-> }')
    // eslint-disable-next-line max-len
      .then(imageCollectionDocument => setImageCollection(imageCollectionDocument));
  }, []);
  return (
    <>
      <PageHelmet />
      <Header />
      <NewsTicker news={news} key='News' />
      <div className={styles.container} key='Home-Page'>

        {attention && attention.length > 0 && (
          <ImageWithText
            key={attention[0].title}
            imgUrl={
              urlFor(attention[0].imageWithMetadata.image)
                .auto('format')
                .url()
            }
            title={attention[0].title}
            text={attention[0].text}
            reversed
          />
        )}

        {projects && projects.length > 0
          && projects.map(project => (
            <>
              {project.titleProductMobileShowing
                ? (
                  <>
                    <ProductMobileShowing
                      key={project.title}
                      title={project.titleProductMobileShowing}
                      imgUrl1={
                        urlFor(project.imagesProductMobileShowing[0])
                          .auto('format')
                          .url()
                      }
                      imgUrl2={
                        urlFor(project.imagesProductMobileShowing[1])
                          .auto('format')
                          .url()
                      }
                      imgUrl3={
                        urlFor(project.imagesProductMobileShowing[2])
                          .auto('format')
                          .url()
                      }
                    />
                    <TextWithLink
                      additionalStyles={cx(styles.textWrapper)}
                      text={project.textCustomerStatement}
                      linkText={project.mobileLinkText}
                      to={project.mobileProjectRef ? `prosjekter/${project.mobileProjectRef.titleHeader}` : null}
                      isExternal={project.isExternal}
                    />
                  </>
                )
                : (
                  <ImageWithText
                    key={project.title}
                    imgUrl={
                      urlFor(project.imageCustomerInfo)
                        .auto('format')
                        .url()
                    }
                    title={project.titleProductShowing}
                    text={project.textProductShowing}
                    linkText={project.linkText}
                    to={project.projectRef ? `prosjekter/${project.projectRef.titleHeader}` : null}
                  />
                )}
            </>
          ))}
        {content && content.length > 0
          && content.map((section, i) => (
            <Fade>
              <TextWithDoubleImages
                key={section.sortId}
                centeredText
                reversed={!!(i % 2)}
                overlap={!!(i % 2)}
                title={section.title}
                text={section.text}
                firstImg={section.imagesWithMetadata.length && (
                  urlFor(section.imagesWithMetadata[0].image)
                    .auto('format')
                    .url()
                )}
                firstImgCaption={section.imagesWithMetadata[0].text || null}
                secondImg={section.imagesWithMetadata.length > 1 && (
                  urlFor(section.imagesWithMetadata[1].image)
                    .auto('format')
                    .url()
                )}
                secondImgCaption={section.imagesWithMetadata[1].text || null}
                linkText={section.linkText}
                to={section.url}
                isExternal={section.isExternal}
              />
            </Fade>
          ))}
        {contactInfo && contactInfo.length > 0
          && contactInfo.map(contact => (
            <Fade>
              <ContactInfo
                key={contact.jobTitle}
                title={contact.jobTitle}
                name={contact.name}
                mail={contact.mail}
                phone={contact.phone}
              />
            </Fade>
          ))}
        {imageCollection && imageCollection.length > 0
              && (
              <div className={styles.random}>
                <div className={styles.imageCollection}>
                  <div className={styles.img1}>
                    <img
                      src={urlFor(imageCollection[0].imagesWithMetadata[0].image)
                        .auto('format')
                        .url()}
                      alt='Image1'
                      className={styles.img}
                    />
                  </div>
                  <div className={styles.img2}>
                    <img
                      src={urlFor(imageCollection[0].imagesWithMetadata[1].image)
                        .auto('format')
                        .url()}
                      alt='Image2'
                      className={styles.img}
                    />
                  </div>
                  <div className={styles.img3}>
                    <img
                      src={urlFor(imageCollection[0].imagesWithMetadata[2].image)
                        .auto('format')
                        .url()}
                      alt='Image3'
                      className={styles.img}
                    />
                  </div>
                  <div className={styles.text}>
                    <TextWithLink
                      additionalStyles={styles.imgColText}
                      text={imageCollection[0].text}
                      linkText={imageCollection[0].linkText}
                      to={imageCollection[0].url}
                      isExternal
                    />
                  </div>
                  <InnitArrowNoPad className={cx(styles.arrow, styles.arr1)} />
                  <InnitArrowNoPad className={cx(styles.arrow, styles.arr2)} />
                  <InnitArrowNoPad className={cx(styles.arrow, styles.arr3)} />
                </div>
              </div>
              )}
      </div>
    </>
  );
};

export default HomePage;
