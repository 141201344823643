import React from 'react';
import PropTypes from 'prop-types';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const SectionWithArrow = ({
  title, content, tags, arrowColor,
}) => (
  <section className={styles.container}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.content}>{content}</p>
    <p className={styles.tags}>{tags}</p>
    <InnitArrow fill={arrowColor} className={styles.arrowStyle} />
  </section>
);

SectionWithArrow.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tags: PropTypes.string.isRequired,
  arrowColor: PropTypes.string.isRequired,
};

export default SectionWithArrow;
