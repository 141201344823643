import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const TextBlock = ({
  title,
}) => (
  <section className={styles.container}>
    {title && <h2 className={styles.title}>{title}</h2>}
  </section>
);

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
};

export default TextBlock;
