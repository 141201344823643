import React from 'react';
import PropTypes from 'prop-types';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const Header = ({
  customerText, title, imgSrc, caption,
}) => (
  <>
    <header className={styles.header}>
      <div className={styles.headerImgContainer}>
        <img src={imgSrc} alt={title} className={styles.HeaderImg} />
        {caption && (
          <div className={styles.captionContainer}>
            <p className={styles.figurecaption}>{caption}</p>
          </div>
        )}
      </div>
      <div className={styles.headerTextContainer}>
        <div className={styles.textWrapper}>
          <h3 className={styles.title}>{title}</h3>
          <h1 className={styles.customerText}>{customerText}</h1>
        </div>
      </div>
    </header>
    <div className={styles.container}>
      <div className={styles.arrowWrapper}>
        <div className={styles.arrowContainer}>
          <InnitArrow className={styles.arrow} />
        </div>
      </div>
    </div>
  </>
);

Header.defaultProps = {
  caption: null,
};

Header.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  customerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default Header;
