import React from 'react';
import PropTypes from 'prop-types';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const Header = ({ title, description }) => (
  <>
    <header className={styles.header}>
      <section className={styles.headerTextWrapper}>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.description}>{description}</h2>
      </section>
      <div className={styles.arrowContainer}>
        <InnitArrow className={styles.arrow} />
        <InnitArrow className={styles.arrow} />
        <InnitArrow className={styles.arrow} />
        <InnitArrow className={styles.arrow} />
      </div>

      <div className={styles.iceArea} />
    </header>
  </>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Header;
