import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextWithLink from '../TextWithLink/TextWithLink';
import DoubleImages from '../DoubleImages/DoubleImages';
import styles from './styles.module.scss';

const TextWithDoubleImages = ({
  title,
  text,
  linkText,
  to,
  firstImg,
  firstImgCaption,
  secondImg,
  secondImgCaption,
  reversed,
  overlap,
  centeredText,
  isExternal,
}) => (
  <div className={cx(
    styles.container,
    {
      [styles.isReversed]: reversed,
      [styles.overlap]: overlap,
      [styles.centeredText]: centeredText,
    },
  )}
  >
    <TextWithLink
      additionalStyles={cx(styles.textContainer,
        { [styles.isReversed]: reversed, [styles.centeredText]: centeredText })}
      title={title}
      text={text}
      linkText={linkText}
      to={to}
      isExternal={isExternal}
    />
    <DoubleImages
      reversed={reversed}
      firstImg={firstImg}
      firstImgCaption={firstImgCaption}
      secondImgCaption={secondImgCaption}
      secondImg={secondImg}
    />
  </div>
);

TextWithDoubleImages.defaultProps = {
  reversed: false,
  title: null,
  linkText: null,
  to: null,
  overlap: false,
  centeredText: false,
  isExternal: false,
  firstImgCaption: null,
  secondImgCaption: null,
};

TextWithDoubleImages.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  linkText: PropTypes.string,
  to: PropTypes.string,
  firstImg: PropTypes.string.isRequired,
  firstImgCaption: PropTypes.string,
  secondImg: PropTypes.string.isRequired,
  secondImgCaption: PropTypes.string,
  reversed: PropTypes.bool,
  overlap: PropTypes.bool,
  centeredText: PropTypes.bool,
  isExternal: PropTypes.bool,
};

export default TextWithDoubleImages;
