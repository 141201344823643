import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import client from 'cms/client';
import ReactGA from 'react-ga';
import { ProjectSample, PageHelmet } from 'components';
import imageUrlBuilder from '@sanity/image-url';
import Header from './Header/Header';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);

const urlFor = source => imgBuilder.image(source);

const HeaderInfo = {
  title: 'PROSJEKTER',
  description: 'Vi skaper konkurransefortrinn for små og store virksomheter.',
};

const ProjectsPage = () => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/prosjekter');
    client.fetch('*[_type=="projects"] | order(sortId){ ..., projectRef->, imageWithMetadata-> }')
      .then(cmsContent => setContent(cmsContent));
  }, []);
  return (
    <>
      <PageHelmet pageTitle='Prosjekter' />
      <Header title={HeaderInfo.title} description={HeaderInfo.description} />
      <div className={styles.container}>
        {content.map((project, i) => (
          <Fade key={project.sortId}>
            <ProjectSample
              imgUrl={
                urlFor(project.imageWithMetadata.image)
                  .auto('format')
                  .url()
              }
              title={project.projectTitle}
              imgCaption={project.imageWithMetadata.text || null}
              description={project.ingress}
              linkText={project.linkText}
              isReverese={!!(i % 2)}
              to={project.projectRef ? `prosjekter/${project.projectRef.titleHeader}` : null}
            />
          </Fade>
        ))}
      </div>
    </>
  );
};

export default ProjectsPage;
