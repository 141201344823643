import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import image from 'assets/images/pal_stueflotten_2.jpg';
import LinkWithArrow from '../LinkWithArrow/LinkWithArrow';
import styles from './styles.module.scss';

const ProjectSample = ({
  title, description, linkText, imgUrl, imgCaption, to, isReverese,
}) => (
  <section className={cx(styles.sampleContainer, { [styles.isReverese]: isReverese })}>
    <div className={styles.sampleImgContainer}>
      <img src={imgUrl} alt={title} className={styles.sampleImg} />
      { imgCaption && (
        <div className={styles.imgCaption}>
          {imgCaption}
        </div>
      )}
    </div>
    <div className={styles.sampleTextContainer}>
      <h2 className={styles.sampleTitle}>{title}</h2>
      <p className={styles.sampleDesc}>{description}</p>
      {to && <LinkWithArrow text={linkText} to={to} />}
    </div>
  </section>
);

ProjectSample.defaultProps = {
  imgUrl: image,
  imgCaption: null,
  isReverese: true,
  to: null,
  linkText: 'Les mer her',
};

ProjectSample.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  imgUrl: PropTypes.string,
  imgCaption: PropTypes.string,
  to: PropTypes.string,
  isReverese: PropTypes.bool,
};

export default ProjectSample;
