import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { PageHelmet, TextWithLink } from 'components';
import styles from './styles.module.scss';

const PageInfo = {
  title: 'PERSONVERNERKLÆRING',
  text: 'innit.no benytter vi informasjonskapsler (cookies) for å måle trafikken til sidene våre. Trafikken logges til Google Analytics og Hotjar. Vi anonymiserer alt som måles, slik at ip-adressen ikke blir logget og vi kan ikke benytte opplysningene til å identifisere enkeltpersoner.',
};

const PrivacyStatement = () => {
  useEffect(() => {
    ReactGA.pageview('/personvern');
  }, []);

  return (
    <>
      <PageHelmet pageTitle='Personvernerklæring' />
      <div className={styles.pageContainer}>
        <section className={styles.pageContent}>
          <TextWithLink title={PageInfo.title} text={PageInfo.text} />
        </section>
      </div>
    </>
  );
};

export default PrivacyStatement;
