import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gaselle from '../../assets/images/gaselle.png';
import styles from './styles.module.scss';

/**
 * @param {string} image - Image as string from proptype enum.
 * @param {string|Array<string>} text - Text as <string> or Array<string>.
 *     Arrays will be rendered as flexbox column on small screens.
 * @param {bool} relativePositioning - Use relative positioning to expand
 *     to 100% of viewport width, without breaking out of document flow.
 */
const Banner = ({ image, text, relativePositioning }) => (
  <div className={cx(styles.banner, { [styles.relativePositioning]: relativePositioning })}>
    {
      image === 'gaselle'
        ? <img src={gaselle} alt='Gaselle' className={cx(styles.image, { [styles[image]]: image })} />
        : null
    }
    {
      Array.isArray(text)
        ? text.map(item => <h2 className={styles.bannerText} key={item}>{item}</h2>)
        : <h2 className={styles.bannerText}>{text}</h2>
    }
  </div>
);

Banner.defaultProps = {
  relativePositioning: false,
  image: null,
};

Banner.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.string,
    ),
  ]).isRequired,
  image: PropTypes.oneOf(['gaselle']),
  relativePositioning: PropTypes.bool,
};

export default Banner;
