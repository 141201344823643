import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { motion, useCycle } from 'framer-motion';
import styles from './styles.module.scss';

const DoubleImages = ({
  firstImg, firstImgCaption, secondImg, secondImgCaption, reversed,
}) => {
  const [animate, toggleAnimate] = useCycle(false, true);
  const constraintsRef = useRef(null);
  const firstImgAnim = {
    show: {
      zIndex: 1,
      transition: {
        duration: 0.4,
      },
    },
    hide: {
      zIndex: 0,
      opacity: [0, 0.0, 1],
      transition: {
        duration: 0.4,
      },
    },
  };

  const secondImgAnim = {
    hide: {
      zIndex: 0,
      opacity: [0, 0.0, 1],
      transition: {
        duration: 0.4,
      },
    },
    show: {
      zIndex: 1,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <motion.div
      ref={constraintsRef}
      onTap={toggleAnimate}
      className={styles.imgsContainer}
    >
      <motion.div
        animate={animate ? 'show' : 'hide'}
        variants={firstImgAnim}
        className={cx(
          styles.imgWrapper,
          styles.firstImg,
          { [styles.isReversed]: reversed },
        )}
      >
        <img
          src={firstImg}
          alt={firstImg}
          className={styles.img}
        />
        { firstImgCaption && (
        <div
          className={styles.imgCaption}
        >
          {firstImgCaption}
        </div>
        )}
      </motion.div>
      <motion.div
        animate={animate ? 'hide' : 'show'}
        variants={secondImgAnim}
        className={cx(
          styles.imgWrapper,
          styles.secondImg,
          { [styles.isReversed]: reversed },
        )}
      >
        <img
          src={secondImg}
          alt={secondImg}
          className={styles.img}
        />
        { secondImgCaption && (
        <div
          className={styles.imgCaption}
        >
          {secondImgCaption}
        </div>
        )}
      </motion.div>
    </motion.div>
  );
};

DoubleImages.defaultProps = {
  firstImgCaption: null,
  secondImgCaption: null,
  reversed: false,
};

DoubleImages.propTypes = {
  reversed: PropTypes.bool,
  firstImg: PropTypes.string.isRequired,
  firstImgCaption: PropTypes.string,
  secondImg: PropTypes.string.isRequired,
  secondImgCaption: PropTypes.string,
};

export default DoubleImages;
