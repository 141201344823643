import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InnitFacebook, InnitInstagram, InnitLinkedin } from '../../assets/icons';
import styles from './styles.module.scss';

const Footer = ({ footerAdress }) => (
  <footer className={styles.footer}>
    <section id='Socials' className={styles.socials}>
      <a href='https://www.facebook.com/innit.no' rel=' noopener noreferrer' target='_blank'><InnitFacebook className={styles.socialIcon} /></a>
      <a href='https://www.linkedin.com/company/innit.no/' rel=' noopener noreferrer' target='_blank'><InnitLinkedin className={styles.socialIcon} /></a>
      <a href='https://www.instagram.com/innit.no/' rel=' noopener noreferrer' target='_blank'><InnitInstagram className={styles.socialIcon} /></a>
    </section>
    <section id='contact-us' className={styles.contactUs}>
      {footerAdress.map(adress => (
        <div className={styles.adress} key={adress.adress}>
          <div className={styles.footerText}>{adress.location}</div>
          <div className={styles.footerText}>{adress.place}</div>
          <div className={styles.footerText}>{adress.adress}</div>
          <div className={styles.footerText}>{adress.zipCode}</div>
        </div>
      ))}
      <Link to='/personvern' className={styles.privacyStatement}>Personvernerklæring</Link>
    </section>
  </footer>
);

Footer.defaultProps = {
  footerAdress: [
    {
      id: 0,
      place: 'HAMAR //Hamar',
      location: 'Melkefabrikken',
      street: 'Storhamargata 44,',
      postAdress: '2317 Hamar',
    },
    {
      id: 1,
      place: 'Lillehammer',
      location: 'Stortorvet',
      street: 'Jernbanegata 13,',
      postAdress: '2609 Lillehammer',
    },
    {
      id: 2,
      place: 'Sandefjord',
      location: 'Torp IT',
      street: 'Østra kullerød 5,',
      postAdress: '3241 Sandefjord',
    },
    {
      id: 3,
      place: 'Innit AS',
      location: 'Orgnr: 983 398 308 MVA',
      street: '+47 4000 3355,',
      postAdress: 'info@innit.no',
    },
  ],
};

Footer.propTypes = {
  footerAdress: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      place: PropTypes.string,
      street: PropTypes.string,
      postAdress: PropTypes.string,
    }),
  ),
};

export default Footer;
