import React from 'react';
import { motion } from 'framer-motion';

const MenuButtonLine = props => (
  <motion.path
    fill='transparent'
    strokeWidth='3'
    stroke='#FFFFFF'
    strokeLinecap='round'
    {...props}
  />
);

export default MenuButtonLine;
