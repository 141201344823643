import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
// import { hydrate, render } from 'react-dom';
import { render } from 'react-snapshot';
import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

render(
  <Router>
    <App />
  </Router>,
  rootElement,
);

/*
if (rootElement.hasChildNodes()) {
  hydrate(
    <Router>
      <App />
    </Router>,
    rootElement,
  );
} else {
  render(
    <Router>
      <App />
    </Router>,
    rootElement,
  );
}
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
