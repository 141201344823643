import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const ContactInfo = ({
  title, name, mail, phone,
}) => (
  <div className={styles.contactInfoWrapper}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.contactInfo}>
      <span>{name}</span>
      <span className={styles.filler}>&#32;&#45;&#32;</span>
      <a href={`mailto: ${mail}`} className={styles.mail}>{mail}</a>
      <span className={styles.filler}>&#32;&#47;&#47;&#32;</span>
      <a href={`tel: ${phone}`} className={styles.phone}>{phone}</a>
    </p>
  </div>
);

ContactInfo.defaultProps = {
  mail: null,
  phone: null,
};

ContactInfo.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  mail: PropTypes.string,
  phone: PropTypes.string,
};

export default ContactInfo;
