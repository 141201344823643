import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { motion, useCycle } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';
import Button from '../Button/Button';
import styles from './styles.module.scss';

const Dropdown = ({
  title,
  list,
  activeItem,
  setActiveItem,
}) => {
  const dropboxRef = useRef(null);
  const [isOpen, toggleIsOpen] = useCycle(false, true);
  const dropboxAnimation = {
    open: {
      height: '100%',
      opacity: 1,
    },
    closed: {
      height: 0,
      opacity: [0, 0],
    },
  };

  const chevronAnimation = {
    open: {
      rotate: 180,
    },
    closed: {
      rotate: 0,
    },
  };

  const activateItem = item => {
    toggleIsOpen();
    setActiveItem(item);
  };

  return (
    <>
      <section className={styles.dropDownConatiner}>
        <motion.button className={styles.dropDownButton} onClick={() => toggleIsOpen()}>
          <span>{activeItem || title}</span>
          <motion.div
            animate={isOpen ? 'open' : 'closed'}
            variants={chevronAnimation}
            className={styles.chevronWrapper}
            transition={{ duration: 0.2 }}

          >
            <FaChevronDown />
          </motion.div>
        </motion.button>
        <motion.div
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          variants={dropboxAnimation}
          className={styles.listContainer}
          ref={dropboxRef}
        >
          {activeItem !== null && isOpen && (
          <Button
            key={title}
            className={styles.listItem}
            onClick={() => activateItem(null)}
          >
            {title}
          </Button>
          )}
          {list.map(listItem => {
            if (activeItem !== listItem && isOpen) {
              return (
                <Button
                  key={listItem}
                  className={styles.listItem}
                  onClick={() => activateItem(listItem)}
                >
                  {listItem}
                </Button>
              );
            } return null;
          })}
        </motion.div>
      </section>
    </>
  );
};

Dropdown.defaultProps = {
  activeItem: null,
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeItem: PropTypes.string,
  setActiveItem: PropTypes.func.isRequired,
};

export default Dropdown;
