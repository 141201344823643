import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserView,
  MobileView,
} from 'react-device-detect';
import { InnitArrow, InnitDot } from 'assets/icons';
import styles from './styles.module.scss';

const ProductMobileShowing = ({
  imgUrl1, imgUrl2, imgUrl3, title,
}) => {
  const [images] = useState([imgUrl1, imgUrl2, imgUrl3]);
  const [selectedImage, setSelectedImage] = useState(0);

  const selectNextImage = () => (
    selectedImage >= images.length - 1 ? setSelectedImage(0) : setSelectedImage(selectedImage + 1)
  );
  return (
    <>
      <MobileView>
        <div className={styles.container}>
          <div className={styles.imgWrapperSecond}>
            <h2 className={styles.title}>{title}</h2>
            <button type='submit' onClick={selectNextImage} className={styles.button}>
              <img src={images[selectedImage]} className={styles.img} alt={title} />
            </button>
          </div>
          <div className={styles.dotContainer}>
            <InnitDot className={styles.dot} />
          </div>
          <div className={styles.arrowContainer}>
            <InnitArrow className={styles.arrow} />
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div className={styles.container}>
          <div className={styles.imgWrapperFirst}>
            <img src={imgUrl1} className={styles.img} alt={title} />
          </div>
          <div className={styles.imgWrapperSecond}>
            <h2 className={styles.title}>{title}</h2>
            <img src={imgUrl2} className={styles.img} alt={title} />
          </div>
          <div className={styles.imgWrapperThird}>
            <img src={imgUrl3} className={styles.img} alt={title} />
          </div>
        </div>
      </BrowserView>
    </>
  );
};

ProductMobileShowing.propTypes = {
  imgUrl1: PropTypes.string.isRequired,
  imgUrl2: PropTypes.string.isRequired,
  imgUrl3: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProductMobileShowing;
