import React from 'react';

const SvgInnitArrow = props => (
  <svg
    data-name='Layer 1'
    viewBox='0 0 400 400'
    {...props}
  >
    <path d='M219 328.34l-30.26-.11 117.01-116.61H52.85v-22.45h253.42L188.94 72l30 .13 128 128.26z' />
    <path
      d='M219 328.34l-30.26-.11 117.01-116.61H52.85v-22.45h253.42L188.94 72l30 .13 128 128.26z'
    />
  </svg>
);

export default SvgInnitArrow;
