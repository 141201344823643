import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as BlockContent from '@sanity/block-content-to-react';
import LinkWithArrow from '../LinkWithArrow/LinkWithArrow';
import styles from './styles.module.scss';

const serializers = {
  types: {
    code: ({ ...props } = {}, { props: { node: { language, code } } } = props) => (
      <pre data-language={language}>
        <code>{code}</code>
      </pre>
    ),
  },
};

const TextWithLink = ({
  title, text, linkText, to, additionalStyles, isExternal,
}) => (
  <div className={cx(styles.textWithLinkContainer, { [additionalStyles]: additionalStyles })}>
    {title && <h2 className={styles.title}>{title}</h2> }
    {(text && Array.isArray(text))
      ? (
        <BlockContent
          renderContainerOnSingleChild
          className={styles.text}
          blocks={text}
          serializers={serializers}
        />
      )
      : <p className={styles.text}>{text}</p>}
    {to && <LinkWithArrow to={to} text={linkText} isExternal={isExternal} />}
  </div>
);

TextWithLink.defaultProps = {
  title: null,
  linkText: null,
  to: null,
  additionalStyles: null,
  isExternal: false,
};

TextWithLink.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  linkText: PropTypes.string,
  to: PropTypes.string,
  isExternal: PropTypes.bool,
  additionalStyles: PropTypes.string,
};

export default TextWithLink;
