import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageHelmet = ({ pageTitle }) => (
  <Helmet>
    <meta charSet='utf-8' />
    {pageTitle
      ? <title>{`Innit - ${pageTitle}`}</title>
      : <title>Innit</title>}
    <link rel='canonical' href='https://www.innit.no/' />
    <meta name='robots' content='index, follow' />
  </Helmet>

);

PageHelmet.defaultProps = {
  pageTitle: null,
};

PageHelmet.propTypes = {
  pageTitle: PropTypes.string,
};

export default PageHelmet;
