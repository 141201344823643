import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useCycle } from 'framer-motion';
import { SvgInnitArribatecsolutions } from 'assets/icons';
import { Link } from 'react-router-dom';
import MenuButton from './MenuButton/MenuButton';
import SlidingMenu from './SlidingMenu/SlidingMenu';
import styles from './styles.module.scss';

const Menu = ({ setIsOpen }) => {
  const [isOpen, toggleIsOpen] = useCycle(false, true);

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen, setIsOpen]);

  return (
    <>
      <motion.nav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
      >
        <div className={styles.menuContainer}>
          <div className={styles.menuWrapper}>
            <Link to='/' className={styles.logoContainer}>
              <SvgInnitArribatecsolutions className={styles.logo} />
            </Link>
            <div className={styles.toggleContainer}>
              <MenuButton toggleIsOpen={toggleIsOpen} />
            </div>
          </div>
        </div>
        <SlidingMenu toggleIsOpen={toggleIsOpen} />
      </motion.nav>
    </>
  );
};

Menu.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
};

export default Menu;
