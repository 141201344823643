import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styles from './styles.module.scss';


const LinkWithArrow = ({ text, to, isExternal }) => {
  const [animate, setAnimate] = useState(false);
  const textAnimation = {
    colorWhite: {
      color: '#FFFFFF',
    },
    colorBlack: {
      color: '#000000',
    },
  };
  const underlineAnimation = {
    scaleUp: {
      height: '100%',
    },
    scaleDown: {
      height: '10%',
    },
  };
  return (
    <motion.div
      onHoverStart={() => setAnimate(true)}
      onHoverEnd={() => setAnimate(false)}
      className={styles.linkWrapper}
    >
      {isExternal
        ? (
          <a href={to} className={styles.linkText}>
            <motion.div
              className={styles.text}
              animate={animate ? 'colorWhite' : 'colorBlack'}
              variants={textAnimation}
            >
              {text}
            </motion.div>
            <motion.div
              className={styles.underline}
              animate={animate ? 'scaleUp' : 'scaleDown'}
              variants={underlineAnimation}
            />
          </a>
        )
        : (
          <Link to={`/${to}`} className={styles.linkText}>
            <motion.div
              className={styles.text}
              animate={animate ? 'colorWhite' : 'colorBlack'}
              variants={textAnimation}
            >
              {text}
            </motion.div>
            <motion.div
              className={styles.underline}
              animate={animate ? 'scaleUp' : 'scaleDown'}
              variants={underlineAnimation}
            />
          </Link>
        )}
    </motion.div>
  );
};

LinkWithArrow.defaultProps = {
  isExternal: false,
};

LinkWithArrow.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
};

export default LinkWithArrow;
