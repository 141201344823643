import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextWithLink from '../TextWithLink/TextWithLink';
import styles from './styles.module.scss';

const TextWithSingleImage = ({
  title, text, centeredText, imgSrc, reversed, caption,
}) => (
  <div className={cx(styles.container, { [styles.isReversed]: reversed })}>
    {imgSrc
    && (
      <div className={styles.imageContainer}>
        <img src={imgSrc} alt={title} className={styles.image} />
        {caption && (
          <div className={styles.captionContainer}>
            <p className={styles.figurecaption}>{caption}</p>
          </div>
        )}
      </div>
    )}
    <div className={cx(styles.textContainer, { [styles.centeredText]: centeredText })}>
      <div className={styles.textWrapper}>
        <TextWithLink title={title} text={text} />
      </div>
    </div>
  </div>
);

TextWithSingleImage.defaultProps = {
  title: null,
  centeredText: false,
  reversed: false,
  imgSrc: null,
  caption: null,
};

TextWithSingleImage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  centeredText: PropTypes.bool,
  imgSrc: PropTypes.string,
  reversed: PropTypes.bool,
  caption: PropTypes.string,
};

export default TextWithSingleImage;
