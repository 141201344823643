import React from 'react';

function SvgInnitDot(props) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 33 32' {...props}>
      <circle
        cx={560.5}
        cy={15}
        r={15}
        transform='translate(-544 1)'
        fill='#FF000A'
        stroke='#F19D9D'
        strokeWidth={2}
        fillRule='evenodd'
      />
    </svg>
  );
}

export default SvgInnitDot;
