import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const MenuLink = ({
  linkText, linkUrl, toggleIsOpen, isExternal,
}) => {
  const arrowRef = useRef(null);

  const arrowAnimation = {
    hover: {
      x: '30%',
      transition: {
        yoyo: Infinity,
        ease: 'linear',
        duration: 0.5,
      },
    },
    rest: {
      x: '0%',
    },
  };

  const linkAnimation = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <motion.div
      variants={linkAnimation}
    >
      {!isExternal ? (
        <Link
          to={linkUrl}
          onClick={() => toggleIsOpen()}
          className={styles.link}

        >
          <motion.div
            className={styles.linkWrapper}
            initial='rest'
            whileHover='hover'
          >
            <div className={styles.linkText}>{linkText}</div>
            <motion.div
              ref={arrowRef}
              variants={arrowAnimation}
            >
              <InnitArrow className={styles.arrow} />
            </motion.div>
          </motion.div>
        </Link>
      ) : (
        <a
          href={linkUrl}
          onClick={() => toggleIsOpen()}
          className={styles.link}
        >
          <motion.div
            className={styles.linkWrapper}
            initial='rest'
            whileHover='hover'
          >
            <div className={styles.linkText}>{linkText}</div>
            <motion.div
              ref={arrowRef}
              variants={arrowAnimation}
            >
              <InnitArrow className={styles.arrow} />
            </motion.div>
          </motion.div>
        </a>
      )}
    </motion.div>
  );
};

MenuLink.defaultProps = {
  isExternal: false,
};

MenuLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
  isExternal: PropTypes.bool,
};

export default MenuLink;
