import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const SingleImage = ({
  imgUrl, caption,
}) => (
  <div className={styles.container}>
    <img src={imgUrl} alt={imgUrl} className={styles.img} />
    {caption && (
      <div className={styles.captionContainer}>
        <p className={styles.figurecaption}>{caption}</p>
      </div>
    )}
  </div>
);

SingleImage.defaultProps = {
  caption: null,
};

SingleImage.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default SingleImage;
