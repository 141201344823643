import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import {
  PageHelmet,
} from 'components';
import image from 'assets/images/404_illustrasjon.png';
import styles from './styles.module.scss';

const Page404 = () => {
  useEffect(() => {
    ReactGA.pageview('/404');
  }, []);

  return (
    <>
      <PageHelmet pageTitle='404' />
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <div className={styles.textContainer}>
            <h3 className={styles.title}>404 - AAAAH, NOE GIKK GALT</h3>
            <p className={styles.text}>Siden du så etter ble ikke funnet på serveren.</p>
            <p className={styles.text}>Ingen vet hvor den er blitt av....</p>
            <div className={styles.linkContainer}>
              <a className={styles.link} href='/'>Gå hjem igjen</a>
            </div>
          </div>
          <div className={styles.imgContainer}>
            <img src={image} alt='404' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
