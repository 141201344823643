import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { SvgInnitArribatecsolutions } from 'assets/icons';
import { Link } from 'react-router-dom';
import MenuLink from '../MenuLink/MenuLink';
import styles from './styles.module.scss';

const SlidingMenu = ({ toggleIsOpen }) => {
  const slidingMenu = {
    open: {
      y: '0',
      transition: {
        ease: 'easeInOut',
        duration: 0.5,
      },
    },
    closed: {
      y: '-100%',
      transition: {
        ease: 'easeInOut',
        duration: 0.5,
        delay: 0.5,
      },
    },
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.5 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const variants1 = {
    open: {
      opacity: 1,
      transition: {
        delay: 0.5,
      },
    },
    closed: {
      opacity: 0,
    },
  };

  return (
    <motion.div className={styles.slidingMenu} variants={slidingMenu}>
      <motion.div className={styles.logoWrapper} variants={variants1}>
        <Link to='/' onClick={toggleIsOpen} className={styles.logoContainer}>
          <SvgInnitArribatecsolutions className={styles.logo} />
        </Link>
      </motion.div>
      <motion.div className={styles.linksWrapper} variants={variants}>
        <MenuLink linkText='Hva vi gjør' linkUrl='/hva-vi-gjor' toggleIsOpen={toggleIsOpen} />
        <MenuLink linkText='Menneskene' linkUrl='/menneskene' toggleIsOpen={toggleIsOpen} />
        <MenuLink isExternal linkText='Jobb hos oss' linkUrl='https://careers.arribatec.com' toggleIsOpen={toggleIsOpen} />
        <MenuLink linkText='Prosjekter' linkUrl='/prosjekter' toggleIsOpen={toggleIsOpen} />
        <MenuLink linkText='Om selskapet' linkUrl='/om' toggleIsOpen={toggleIsOpen} />
        <MenuLink linkText='På lag med fremtiden' linkUrl='/paa-lag-med-fremtiden' toggleIsOpen={toggleIsOpen} />
        <MenuLink isExternal linkText='Kundeservice' linkUrl='https://kundeservice.innit.no/' toggleIsOpen={toggleIsOpen} />

      </motion.div>
    </motion.div>
  );
};

SlidingMenu.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
};

export default SlidingMenu;
