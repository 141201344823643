import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import EmployeeImage from 'assets/images/unknown-person.jpg';
import styles from './styles.module.scss';

const EmployeeInfo = ({
  name, title, mail, imgUrl,
}) => (
  <div className={styles.employeeContainer}>
    <img src={imgUrl} alt={name} className={styles.employeeImage} />
    <div className={styles.employeeText}>{name}</div>
    <div className={styles.employeeText}>{title}</div>
    <a className={cx(styles.employeeText, styles.employeeMail)} href={`mailto:${mail}`}>{mail}</a>
  </div>
);

EmployeeInfo.defaultProps = {
  imgUrl: EmployeeImage,
  title: null,
  mail: null,
};

EmployeeInfo.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  mail: PropTypes.string,
};

export default EmployeeInfo;
