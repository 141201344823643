import React from 'react';
import HeaderImg from 'assets/images/MenneskeneV2.png';
import styles from './styles.module.scss';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.headerImgContainer}>
      <img src={HeaderImg} alt='Header pic' className={styles.headerImg} />
    </div>
  </header>
);

export default Header;
