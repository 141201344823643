import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Header = ({ title, description }) => (
  <>
    <header className={styles.header}>
      <section className={styles.headerTextWrapper}>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.description}>{description}</h2>
      </section>
    </header>
  </>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Header;
