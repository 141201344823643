import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const FactsBlock = ({
  imgUrl, title, text,
}) => (
  <div className={styles.factsContainer}>
    <section className={styles.container}>
      <div className={styles.imgWrapper}>
        <img src={imgUrl} alt={title} className={styles.img} />
      </div>
      <div className={styles.textBlock}>
        {title && (<h2 className={styles.title}>{title}</h2>)}
        <p className={styles.text}>{text}</p>
      </div>
    </section>
  </div>
);

FactsBlock.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default FactsBlock;
