import React from 'react';

function SvgInnitArrowNoPad(props) {
  return (
    <svg viewBox='0 0 217.73 249.76' width='calc(0.871 * 20vh)' height='20vh' {...props}>
      <path
        d='M0 141.09l.1-25.7 99.04 99.42V0h19.07v215.25l99.52-99.66-.11 25.49-108.95 108.68L0 141.09z'
      />
      <path
        d='M0 141.09l.1-25.7 99.04 99.42V0h19.07v215.25l99.52-99.66-.11 25.49-108.95 108.68L0 141.09z'
      />
    </svg>
  );
}

export default SvgInnitArrowNoPad;
