import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { InnitArrow } from 'assets/icons';
import styles from './styles.module.scss';

const NewsTicker = ({ news }) => {
  const tickerAnimation = {
    ticker: {
      x: '-100%',
      transition: {
        loop: Infinity,
        duration: news.length * 20,
        ease: 'linear',
      },
    },
  };
  return (
    <>
      { news && news.length > 0 && (
      <motion.div
        className={styles.newsTicker}
      >
        <motion.ul
          variants={tickerAnimation}
          animate='ticker'
          className={styles.tickerWrapper}
        >
          {news && news.map(string => (
            <li className={styles.news} key={string}>
              <InnitArrow className={styles.arrow} />
              {string}
            </li>
          ))}
        </motion.ul>
      </motion.div>
      )}
    </>
  );
};

NewsTicker.propTypes = {
  news: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NewsTicker;
