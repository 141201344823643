import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import client from 'cms/client';
import { PageHelmet, TextWithLink } from 'components';
import styles from './styles.module.scss';

const QmthPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    ReactGA.pageview('/qualified-multitenant-hoster-qmth-program');
    client.fetch('*[_type=="qmth"]')
      .then(cmsContent => {
        setContent(cmsContent[0]);
      });
  }, []);

  return (
    <>
      <PageHelmet pageTitle='Qualified Multitenant Hoster Program' />
      <div className={styles.pageContainer}>
        <section className={styles.pageContent}>
          { content ? <TextWithLink text={content.text} /> : null }
        </section>
      </div>
    </>
  );
};

export default QmthPage;
