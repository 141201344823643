import React, { useState, useEffect } from 'react';
import { PartnerLogo, SectionWithArrow, PageHelmet } from 'components';
import Fade from 'react-reveal/Fade';
import client from 'cms/client';
import ReactGA from 'react-ga';
import imageUrlBuilder from '@sanity/image-url';
import Header from './Header/Header';
import styles from './styles.module.scss';

const HeaderInfo = {
  title: 'HVA VI GJØR',
  description: 'Vi hjelper kundene våre med å oppdage, skape og ta vare på digitale forretningsmuligheter.',
};

const imgBuilder = imageUrlBuilder(client);

const urlFor = source => imgBuilder.image(source);

const WhatWeDoPage = () => {
  // state to store page content
  const [content, setContent] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/hva-vi-gjor');
    client.fetch('*[_type=="services"] | order(sortId)')
      .then(cmsContent => setContent(cmsContent));

    client.fetch('*[_type=="collaborators"] ')
      .then(cmsPartners => setPartners(cmsPartners));
  }, []);

  const color = id => {
    const colors = ['#172727', '#ff000a', '#73120d'];
    return colors[id % 3];
  };

  return (
    <>
      <PageHelmet pageTitle='Hva vi gjør' />
      <Header title={HeaderInfo.title} description={HeaderInfo.description} />
      <div className={styles.pageContainer}>
        {content.map(section => (
          <Fade key={section.sortId}>
            <SectionWithArrow
              key={section.sortId}
              title={section.serviceTitle}
              content={section.serviceText}
              tags={section.tags}
              arrowColor={color(section.sortId)}
            />
          </Fade>
        ))}
        <div className={styles.partnersContainer}>
          {partners.map(partner => (
            <PartnerLogo
              key={partner.serviceTitle}
              name={partner.serviceTitle}
              logo={urlFor(partner.image)
                .auto('format')
                .url()}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default WhatWeDoPage;
