import React from 'react';
import PropTypes from 'prop-types';
import MenuButtonLine from './MenuButtonLine/MenuButtonLine';
import Button from '../../Button/Button';
import styles from './styles.module.scss';

const MenuButton = ({ toggleIsOpen, ...restProps }) => (
  <Button className={styles.button} onClick={() => toggleIsOpen()} {...restProps}>
    <svg width='24' height='22' viewBox='0 0 24 22'>
      <MenuButtonLine
        variants={{
          closed: { d: 'M 2 2.5 L 21 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
        transition={{ duration: 0.5 }}
      />
      <MenuButtonLine
        d='M 2 9.423 L 21 9.423'
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.5 }}
      />
      <MenuButtonLine
        variants={{
          closed: { d: 'M 2 16.346 L 21 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
        transition={{ duration: 0.5 }}
      />
    </svg>
  </Button>
);

MenuButton.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
};

export default MenuButton;
