import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, children, ...restProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <button type='button' onClick={onClick} {...restProps}>
    {children}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
