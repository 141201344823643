import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LinkWithArrow from '../LinkWithArrow/LinkWithArrow';
import styles from './styles.module.scss';

const ImageWithText = ({
  imgUrl, title, text, brand, reversed, linkText, to,
}) => (
  <section className={styles.container}>
    <div className={cx(styles.imgWrapper, { [styles.isReversed]: reversed })}>
      <img src={imgUrl} alt={imgUrl} className={styles.img} />
    </div>
    <div
      className={cx(styles.textBlock, { [styles.isReversed]: reversed, [styles[brand]]: brand })}
    >
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>{text}</p>
      {to && <LinkWithArrow text={linkText} to={to} />}
    </div>
  </section>
);

ImageWithText.defaultProps = {
  brand: 'innit-ice',
  reversed: false,
  linkText: '',
  to: null,
};

ImageWithText.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  brand: PropTypes.string,
  reversed: PropTypes.bool,
  linkText: PropTypes.string,
  to: PropTypes.string,
};

export default ImageWithText;
