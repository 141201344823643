import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import client from 'cms/client';
import { PageHelmet, TextWithLink, SingleImage } from 'components';
import Iframe from 'react-iframe';
import imageUrlBuilder from '@sanity/image-url';
import styles from './styles.module.scss';

const imgBuilder = imageUrlBuilder(client);
const urlFor = source => imgBuilder.image(source);

const BackePage = () => {
  const [ingress, setIngress] = useState(null);
  const [video, setVideo] = useState(null);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    ReactGA.pageview('/informasjon-til-backes-brukere');
    client.fetch('*[_type=="backeIngress"]')
      .then(cmsContent => {
        setIngress(cmsContent[0]);
      });
    client.fetch('*[_type=="backeVideo"]')
      .then(cmsContent => {
        setVideo(cmsContent[0]);
      });
    client.fetch(
      `*[_type=="backeStep"]{...,image-> } | 
      order(sortId asc)`,
    )
      .then(cmsContent => {
        setSteps(cmsContent);
      });
  }, []);

  return (
    <>
      <PageHelmet pageTitle='Informasjon til Backes brukere' />
      <div className={styles.pageContainer}>
        <section className={styles.pageContent}>
          { ingress ? (
            <TextWithLink text={ingress.text} />
          ) : null }
          { video ? (
            <Iframe url={video.embedUrl} className={styles.videoFrame} />
          ) : null}
          { steps.length ? steps.map(step => (
            <div id={step.sortId} className={styles.step} key={step.title}>
              <TextWithLink text={step.text} />
              {Object.prototype.hasOwnProperty.call(step, 'image') && (
                <SingleImage
                  imgUrl={
                    urlFor(step.image.image)
                      .auto('format')
                      .url()
                  }
                />
              )}
            </div>
          )) : null }
        </section>
      </div>
    </>
  );
};

export default BackePage;
