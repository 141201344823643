import React from 'react';
import { MdMailOutline, MdPerson, MdCall } from 'react-icons/md';
import { IoIosChatbubbles } from 'react-icons/io';
import CSImage from 'assets/images/pal_stueflotten_2.jpg';
import ButtonWithIconText from '../ButtonWithIconText/ButtonWithIconText';
import styles from './styles.module.scss';

const mailIcon = <MdMailOutline className={styles.icon} />;
const chatIcon = <IoIosChatbubbles className={styles.icon} />;
const myPageIcon = <MdPerson className={styles.icon} />;


const CustomerService = () => (
  <div className={styles.cSContainer}>
    <img src={CSImage} alt='Customer Service' className={styles.cSImage} />
    <div className={styles.cSBody}>
      <div className={styles.cSTitle}>Kundeservice er her for å hjelpe deg!</div>
      <section className={styles.cSButtons}>
        <ButtonWithIconText icon={mailIcon} text='Mail' />
        <ButtonWithIconText icon={chatIcon} text='Chat med oss' />
        <ButtonWithIconText icon={myPageIcon} text='Min side' />
      </section>
      <section className={styles.cSFooter}>
        <div className={styles.cSFooterText}>Ring oss på</div>
        <a href='tel:40003355'>
          <div className={styles.callUs}>
            <MdCall className={styles.icon} />
            <div className={styles.callText}>4000 3355</div>
          </div>
        </a>
      </section>

    </div>
  </div>
);

export default CustomerService;
